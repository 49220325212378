import Img from 'gatsby-image'
import React from 'react'
import styles from './header.module.css'

export default ({ data }) => (
  <div className={styles.waveBanner}>
    <header className={styles.headerGrid}>
      <Img
        className={styles.headerImage}
        alt={data.name}
        fluid={data.smallBannerImage.fluid}
      />
      <div className={styles.titleGrid}>
        <h1 className={styles.headerTitle}> 
          {data.bannerText} 
        </h1> 
        <h2 className={styles.headerDetails}>{data.bannerSubtext}</h2>
      </div>
    </header>
    <div class="container"> 
        <svg viewBox="0 0 500 500" 
            preserveAspectRatio="xMinYMax slice"
            className={styles.secondSvg}> 
              
            <path d="
              M 0,150
              C 150,200 300,0 500,100
              L 500,00
              L0, 0
              Z"> 
            </path> 
        </svg> 
    </div> 
      
    <div class="container"> 
        <svg viewBox="0 0 500 500" 
            preserveAspectRatio="xMinYMax slice"
            className={styles.firstSvg}> 
              
            <path d="
              M 0,80
              C 300,0 400,300 500,50
              L 500,00 L0, 0 Z"> 
            </path> 
        </svg> 
    </div> 
      
    <div class="container"> 
        <svg viewBox="0 0 500 500" 
            preserveAspectRatio="xMinYMax slice"
            className={styles.thirdSvg}> 
              
            <path d="M 0,150
              C 200,250 350,0 500, 100
              L500, 00
              L0, 0
              Z"> 
            </path> 
        </svg> 
    </div> 
  </div>
)
