import favicon from '../images/favicon.ico'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSection from '../components/header'
import Layout from '../components/layout'
import React from 'react'
import styles from './thanks.module.css';

class ThankYouPage extends React.Component {
  render() {
    const [banner] = get(this, 'props.data.allContentfulHomePageBanner.edges')
    const [thanks] = get(this, 'props.data.allContentfulThankYou.edges')
    const siteTitle = 'Thank You'
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} >
            <link rel="icon" href={favicon} />
          </Helmet>
          <HeaderSection data={banner.node} />
          <div className={styles.thanksContent}>
            <h2 className={styles.thankYou}>{thanks.node.header}</h2>
            <div className={styles.message} dangerouslySetInnerHTML={ { __html: thanks.node.message.childMarkdownRemark.html } }></div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ThankYouPage

export const pageQuery = graphql`
  query ThanksQuery {
    allContentfulHomePageBanner {
      edges {
        node {
          bannerText
          bannerSubtext
          smallBannerImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulThankYou {
      edges {
        node {
          header
          message{
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`